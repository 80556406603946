<template>
  <div id="cal">
  <div>
  <br><br>
  <h2 class="title">Calendar of Events</h2>
  </div>
  <FullCalendar ref="fullCalendar" :options="calendarOptions"/>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import { formatDate } from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
import listPlugin from '@fullcalendar/list'

export default {
  name: "Calendar",
  components: {
    FullCalendar 
  },
  data() {
    return {
      calendarOptions: {
        plugins: [ dayGridPlugin, interactionPlugin, googleCalendarPlugin, listPlugin ],
        initialView: 'list',
        duration: { months: 12},
        initialDate: '2021-10-01',
        googleCalendarApiKey: 'AIzaSyATa6jtDDyPV20MPoYi6uckNBx41kB-buQ',
        eventClick: this.handleDateClick,
        events: {
          googleCalendarId: 'c_t0eruqtfu1a958ih7elgd2f1a0@group.calendar.google.com',
          color:'white',
            checked: 'true'
          
        },
        cal: ''
      }
    };
  },
  methods: {
    eventRender: function(arg){
      console.log("hi" + arg.el);

    },
    handleDateClick: function(arg) {
      console.log(arg.jsEvent.target);
      arg.jsEvent.preventDefault();

      let element = arg.jsEvent.target;
      if(element.parentElement.className == "fc-list-event-title"){
          console.log("title clicked!!")
      }


      let calendar = this.$refs.fullCalendar.getApi();
      let cal = calendar.getEvents();
      console.log(cal, arg.event.allDay)
      let formattedStartDate = formatDate(arg.event.start, {
        hour: 'numeric',
        minute: '2-digit',
        meridiem: 'short'
        });
      let formattedEndDate = formatDate(arg.event.end, {
        hour: 'numeric',
        minute: '2-digit',
        meridiem: 'short'
        });
      console.log(formattedStartDate);
      if(arg.event.extendedProps.checked !=true){

      if(arg.event.extendedProps.description != undefined){
        if(arg.event.allDay == false){
          arg.el.innerHTML = '<tr class="fc-list-event fc-event fc-event-start fc-event-end fc-event-past"><td class="fc-list-event-time">' + formattedStartDate + " - " + formattedEndDate + '</td><td class="fc-list-event-graphic"><span class="fc-list-event-dot" style="border-color: white;"></span></td><td class="fc-list-event-title"><a href="https://www.google.com/calendar/event?eid=XzZkMTNpZDFwNmgxamFiOWg3MHNqNmI5azhvbzM0YmExODhvNDhiYTM2b3FrYWc5bjZnczMyYzIyNnMgY190MGVydXF0ZnUxYTk1OGloN2VsZ2QyZjFhMEBn">' + arg.event.title + '</a></td><div class="event"><div><b>' + arg.event.extendedProps.location + '</b></div>' + '<div>'+ arg.event.extendedProps.description + '</div></div>';
        }else if(arg.event.allDay == true){
          arg.el.innerHTML = '<tr class="fc-list-event fc-event fc-event-start fc-event-end fc-event-past"><td class="fc-list-event-time">' + "all-day" + '</td><td class="fc-list-event-graphic"><span class="fc-list-event-dot" style="border-color: white;"></span></td><td class="fc-list-event-title"><a href="https://www.google.com/calendar/event?eid=XzZkMTNpZDFwNmgxamFiOWg3MHNqNmI5azhvbzM0YmExODhvNDhiYTM2b3FrYWc5bjZnczMyYzIyNnMgY190MGVydXF0ZnUxYTk1OGloN2VsZ2QyZjFhMEBn">' + arg.event.title + '</a></td><div class="event"><div><b>' + arg.event.extendedProps.location + '</b></div>' + '<div>'+ arg.event.extendedProps.description + '</div></div>';

          }
      }else if (arg.event.extendedProps.description == undefined){
        arg.el.innerHTML = '<tr class="fc-list-event fc-event fc-event-start fc-event-end fc-event-past"><td class="fc-list-event-time">' + formattedStartDate + " - " + formattedEndDate + '</td><td class="fc-list-event-graphic"><span class="fc-list-event-dot" style="border-color: white;"></span></td><td class="fc-list-event-title"><a href="https://www.google.com/calendar/event?eid=XzZkMTNpZDFwNmgxamFiOWg3MHNqNmI5azhvbzM0YmExODhvNDhiYTM2b3FrYWc5bjZnczMyYzIyNnMgY190MGVydXF0ZnUxYTk1OGloN2VsZ2QyZjFhMEBn">' + arg.event.title + '</a></td><div class="event"><div><b>' + arg.event.extendedProps.location + '</b></div></div>';

        }
        console.log(arg.event.checked + "true");

let anchor = event.target.closest("a");   // Find closest Anchor (or self)
      if (!anchor) return; 
      console.log(anchor.getAttribute('href'))
      let url = anchor.getAttribute('href');
      if (!url.includes("https://www.google.com/calendar/event")){
        window.open(url);
      }

    

        arg.event.checked = true;
        console.log(arg.event.checked);
      }else if (arg.event.checked == true){
        //arg.event.checked = false;
        console.log(arg.event.checked + "false");
      }
    }
  }
}
</script>

<style>
:root {
--fc-list-event-dot-width: 0px;
--fc-list-event-hover-bg-color: #78a6a1;
}
  #cal{
    padding:1em;
    padding-top:2em;
    }
  .fc-event, .fc-h-event{
    display:block;
    background-color:#78a6a1;
    color: white;
    padding:2px;
    border:none;
    padding:1em;
  }
  .fc-list-event{
    margin:1em;
  }
  .fc-list-day{
    display:block;
    color:#2c3e50;
    padding-top:2em;
    padding-left:1em;
  }
  .fc-list-day-text{
    padding-right:2em;
  }
  .fc-list-event-title, .fc-list-event-time, .fc-list-event-graphic{
    font-weight:bold;
    border: none!important;
  }
  .fc-event:hover, .fc-list-event:hover{
  }
  .fc-daygrid-event-dot{
    display:none;

  }
  .event{
    background-color:rgb(209, 228, 221);
    color: #597774;
    display:block;
    padding:1em;
  }

  @media screen and (max-width: 700px) {
    .fc-list-day, .fc-list-event, .event, .fc-list-table, .fc-list-event-title {
      max-width:90%;
      display:flex;
      flex-direction:row;
      flex-wrap: wrap;
      flex-shrink: 3;
      font-size:13px;
    }
    .fc, .fc-view-harness, .fc-view-harness-active{
    height:80vh !important;

  }

  }
</style>